import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnect from "@walletconnect/web3-provider";

export const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK, 
    options: {
      infuraId: "6b1cc54cef6649b594fa43d67b131152"
    }
  },
  walletconnect: {
    package: WalletConnect, 
    options: {
      infuraId: "6b1cc54cef6649b594fa43d67b131152"
    }
  } 
};