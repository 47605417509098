import './reset.css';
import './variable.css';
import './style.css';
import './inputbox.css';
import './responsive.css';

import { useEffect, useState } from "react";

import failed from "./assets/failed.svg";
import successful from "./assets/successful.svg";
import hidden from "./assets/hidden.gif";
import logo from "./assets/logo.png";
import metamask from "./assets/metamask.svg";
import nalikes from "./assets/nalikes.svg";

import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { providerOptions } from "./provideroptions.js";
import { toHex, truncateAddress } from "./utils";

import Contract_ABI from "./Contract_ABI.json";
const Contract_Address = "0xb3C94f446C30114A3aff0F60438388D31BEAB696"

const web3Modal = new Web3Modal({
  cacheProvider: false, // optional
  providerOptions: providerOptions, // required
  disableInjectedProvider: false
});

const m_network = "mainnet"
// mainnet = 1; goerli = 5
const m_chain = 1
const m_infura = "6b1cc54cef6649b594fa43d67b131152"

function App() {
  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [signer, setSigner] = useState();

  const [account, setAccount] = useState();
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  const [nftContract, setNftContract] = useState(null);

  const [totalMinted, setTotalMinted] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [cost, setCost] = useState(0);
  const [accountBalance, setAccountBalance] = useState(0);

  let mintAmount = 1;

  const [paused, setPaused] = useState(true);

  // EFFECTS

  useEffect(() => {
    window.addEventListener('load', () => {
      document.getElementById('preloading').style.display = 'none';
    });

    web3Modal.clearCachedProvider();
    initializeApp();
    // if (web3Modal.cachedProvider) {
    //   console.log("Loaded cached", web3Modal.cachedProvider);
    //   connectWallet();
    // }
  }, []);

  useEffect(() => {
    if (provider?.on) {

      const handleAccountsChanged = (accounts) => {
        console.log("accountsChanged", accounts);
        if (accounts) setAccount(accounts[0]);
      };

      const handleChainChanged = (_hexChainId) => {
        console.log("chainChanged", _hexChainId);
        switchNetwork();
      };

      const handleDisconnect = (err) => {
        console.log("disconnect", err);
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);

  useEffect(() => {
    if (isWalletConnected) {
      loadAppData();
    }
  }, [isWalletConnected, account])

  // CONNECTION

  const connectWallet = async () => {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const signer = library.getSigner();
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();

      setProvider(provider);
      setLibrary(library);
      if (accounts) setAccount(accounts[0]);
      setSigner(signer);

      if (network.chainId !== m_chain)
        await switchNetwork();

      setIsWalletConnected(true);

      document.querySelector("#inputButton").style.display = 'flex';
      document.querySelector(".disconnectButton").style.display = 'flex';

      console.log("Connected.")

    } catch (err) {
      console.log(err);
    }
  }

  const disconnect = async () => {
    await web3Modal.clearCachedProvider();

    setAccount();
    setAccountBalance(0);
    setIsWalletConnected(false);

    document.querySelector("#inputButton").style.display = 'none';
    document.querySelector(".disconnectButton").style.display = 'none';

    mintAmount = 1;
    console.log("Disconnected.")
  }

  // APP

  const initializeApp = async () => {
    const temp_provider = new ethers.providers.InfuraProvider(m_network, m_infura)
    const tempInstance = await new ethers.Contract(Contract_Address, Contract_ABI, temp_provider);

    // Paused
    tempInstance.functions.paused().then((response) => {
      let state = response[0];
      console.log("(INIT) Contract Paused: " + state);
      setPaused(state);
    });

    // TotalMinted
    tempInstance.functions.totalSupply(1).then((response) => {
      let bal = parseInt(response[0]["_hex"]);
      console.log("(INIT) Total Minted: " + bal);
      setTotalMinted(bal);
    });

    // MaxSupply
    tempInstance.functions.maxSupply().then((response) => {
      let bal = parseInt(response[0]["_hex"]);
      console.log("(INIT) Max Supply: " + bal);
      setMaxSupply(bal);
    });

    // Cost
    tempInstance.functions.price().then((response) => {
      let costInWei = parseInt(response[0]["_hex"]);
      console.log("(INIT) Mint cost (wei) " + costInWei)
      setCost(costInWei);
    });
  }

  const loadAppData = async () => {
    if (!isWalletConnected)
      return;
    try {
      console.log("Loading app data. Account:", account);

      // Account Balance
      library.getBalance(account).then((response) => {
        const bal = parseFloat(ethers.utils.formatEther(response)).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0]
        console.log("Account balance: " + bal + " ETH");
        setAccountBalance(response.toString());
      });

      let contractInstance = await new ethers.Contract(Contract_Address, Contract_ABI, signer);
      setNftContract(contractInstance);

      // Paused
      contractInstance.functions.paused().then((response) => {
        let state = response[0];
        console.log("Contract Paused: " + state);
        setPaused(state);
      });

      // TotalMinted
      contractInstance.functions.totalSupply(1).then((response) => {
        let bal = parseInt(response[0]["_hex"]);
        console.log("Total Minted: " + bal);
        setTotalMinted(bal);
      });

      // MaxSupply
      contractInstance.functions.maxSupply().then((response) => {
        let bal = parseInt(response[0]["_hex"]);
        console.log("Max Supply: " + bal);
        setMaxSupply(bal);
      });

      // Cost
      contractInstance.functions.price().then((response) => {
        let costInWei = parseInt(response[0]["_hex"]);
        console.log("Mint cost (wei): " + costInWei)
        setCost(costInWei);
      });

    } catch (err) {
      let message = JSON.parse(err)["message"];
      console.log(message);
    }
  }

  // UTILITIES

  function enableProcessingStatus () {
    document.querySelector("#processingStatus").style.display = "block";
    document.querySelector("#failedStatus").style.display = "none";
    document.querySelector("#successfulStatus").style.display = "none";
  }

  function enableFailedStatus () {
    document.querySelector("#processingStatus").style.display = "none";
    document.querySelector("#failedStatus").style.display = "block";
    document.querySelector("#successfulStatus").style.display = "none";
  }

  function enableSuccessfulStatus () {
    document.querySelector("#processingStatus").style.display = "none";
    document.querySelector("#failedStatus").style.display = "none";
    document.querySelector("#successfulStatus").style.display = "block";
  }

  const switchNetwork = async () => {
    await library.provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: toHex(m_chain) }]
    });
    console.log("Switched network.");
    connectWallet();
  }

  const updateMintAmount = (counter) => {
    let currentValue = parseInt(document.getElementById("quantity").value);
    currentValue += parseInt(counter);
    if(currentValue <= 1) currentValue = 1;
    if(currentValue >= 5) currentValue = 5;

    mintAmount = currentValue;
    console.log({mintAmount})

    document.getElementById("quantity").value = currentValue;
    document.getElementById("quantity").placeholder = currentValue;
  }

  // MINT FUNCTIONS

  const publicMint = async () => {

    if (nftContract !== undefined) {

      document.querySelector("#popup").style.display = "flex";
      document.querySelector("#status").innerHTML = "Transaction Processing...";
      document.querySelector("#message").innerHTML = "";
      enableProcessingStatus();

      mintAmount = parseInt(mintAmount);
      if (mintAmount < 1 || mintAmount > 5) mintAmount = 1;

      let totalCost = cost * mintAmount;

      let checksPassed = true;
      let errMessage = "";

      if (accountBalance < totalCost) {
        console.log("Insufficient Funds.");
        checksPassed = false;
        errMessage = "Insufficient Funds.";
      }

      if (totalMinted + mintAmount > maxSupply) {
        console.log("Token Supply Exceeded.");
        checksPassed = false;
        errMessage = "Token Supply Exceeded.";
      }

      console.log({ checksPassed });

      if (!checksPassed) {
        document.querySelector("#status").innerHTML = "Transaction Failed!"
        document.querySelector("#message").innerHTML = errMessage;
        enableFailedStatus(); 
        return;
      }

      console.log("Minting " + mintAmount + " NFT(s), Cost: " + ethers.utils.formatEther(totalCost.toString()) + " ETH");

      try {
        await nftContract.mint(account, mintAmount, { from: account, value: totalCost.toString() }).then((res) => {
          enableSuccessfulStatus();
          document.querySelector("#status").innerHTML = "Transaction Sent!"
          document.querySelector("#message").innerHTML = "View Transaction Status on <a target='_blank' href='https://etherscan.io/tx/" + res.hash + "'>Etherscan</a>"
          console.log(res);
        });
      } catch (err) {
        enableFailedStatus();
        document.querySelector("#status").innerHTML = "Transaction Failed!";
        document.querySelector("#message").innerHTML = "Your transaction failed, Error Code: " + err.code;
        console.error("------ TRANSACTION ERROR ------");
        console.error(err);
        console.error("------ TRANSACTION ERROR ------");
      }
    }
  }

  const NFTPayPopup = () => {
    const el = document.getElementById('nftpaypopup');
    if(el.style.display === "none")
      el.style.display = "flex";
    else
      el.style.display = "none";
  }

  return (
    <div className='main' >
      <div className='backgroundImage'></div>
      {/* PRELOADER */}
      <div className="preloading popup" id="preloading">
        <img src={logo} alt="" />
      </div>

      <div id='nftpaypopup' className="nftpaypopup" style={{display: "none"}}>
        <div className="nftpayinner">
          <h1 className="closeNFTPay" onClick={() => {document.getElementById('nftpaypopup').style.display = 'none';}}>x</h1>
          <iframe className="nftpay_iframe" title='NFTPay' src="https://payments.nftpay.xyz/iframe/iframe_pay/52dfd12c-8386-42dd-9a94-639f6a239150?"></iframe>
        </div>
      </div>

      {/* POPUP */}
      <div className="popup" id="popup">
        <div className="popupBox">
          <div className="popupHeading">
            <h1 className="statusHeading"></h1>
            <h1 className="closeX" onClick={() => {document.getElementById('popup').style.display = 'none';}}>X</h1>
          </div>
          <div id="failedStatus" ><img src={failed}/></div>
          <div id="successfulStatus" ><img src={successful}/></div>
          <div id="processingStatus" ><div className="spinner"></div></div>
          <h1 id="status"></h1>
          <h2 id="message"></h2>
        </div>
      </div>

      {/* NAV BAR */}
      <nav>
        <div className="row">
          <div className="col">
            <img id="navImg" src={logo} alt="Bugatti NFT" />
          </div>
        </div>
      </nav>

      {/* MAIN SECTION */}
      <section className="mainSection">
        <div className="mainHeading" id="mainHeading">
          <h1>#LEARNCRYPTO MINT</h1>
        </div>
        <div className="stats">
            <div className="subheading">
                <h2 className="title">
                    Supply
                </h2>
                <h2 className="value">
                  {totalMinted} / {maxSupply}
                </h2>
            </div>
            <div className="subheading">
                <h2 className="title">
                    Price
                </h2>
                <h2 className="value">
                    {ethers.utils.formatEther(cost.toString())} ETH
                </h2>
            </div>
        </div>
        <div className="canMint" hidden={!paused}>
          MINT PAUSED
        </div>
        <div className="nftImage">
            <img id="mainNftImage" src={hidden} alt="#LEARNCrypto NFT"/>
            <div className="quantity buttons_added" id="inputButton">
                <input disabled={paused} type="button" value="-" className="minus" onClick={() => {updateMintAmount(-1)}}/>
                <input disabled={paused}
                    type="number" step="1" min="1" max="5" name="quantity" id="quantity" value="1" placeholder="1" title="Qty" className="input-text qty text" 
                    size="4" pattern="" inputmode=""/>
                <input disabled={paused} type="button" value="+" className="plus" onClick={() => {updateMintAmount(1)}}/>
            </div>
        </div>
        <a className="disconnectButton" onClick={() => {disconnect();}}>
          Connected: {truncateAddress(account)}
        </a>
        <button className="mintButton" hidden={isWalletConnected} onClick={() => {connectWallet();}}>
          <div className="cryptoButton">
            <img src={metamask}/> &nbsp; <h3>Connect Wallet</h3>
          </div>
        </button>
        <button className="mintButton" hidden={paused || !isWalletConnected} onClick={() => {publicMint();}}>
          <div className="cryptoButton">
            <img src={metamask}/> &nbsp; Mint
          </div>
        </button>
        <button hidden={paused} onClick={() => {NFTPayPopup();}} className="mintButton" id="card">Buy with Credit Card</button>
      </section>
      <section className='content'>
      <p>
      <br/>
      <h1>Overview:</h1>
      <br/>
      #LEARNcrypto is a 10k NFT collection that provides its owner with a social sharable NFT badge identifying its holder as being committed to the development and distribution of unbiased educational content focusing on cryptocurrency, blockchain technology and web3. #LEARNcrypto is the first NFT launch of the American Crypto Academy and established the Academy’s Founding Members.
      <br/><br/>
      <h1>Proceeds:</h1>
      <br/>
      All proceeds from #LEARNcrypto NFT sale will go toward the immediate development and distribution of free educational content to lawmakers, regulators, government administrators and non-profit educational institutions.
      <br/><br/>
      <h1>Utility:</h1>
      <br/>
      #LEARNcrypto NFT holders will also be granted permanent PREMIUM membership to the American Crypto Academy along with the ability to grant 10 (ten) BASIC Academy memberships to friends and family. As part of the American Crypto Academy’s permanent community, #LEARNcrypto NFT holders will also enjoy preferential and discounted access to IRL events and conferences sponsored by the Academy and will have access to premium staking yields on LEARN tokens.
      <br/><br/>
      <h1>About American Crypto Academy:</h1>
      <br/>
      The American Crypto Academy is a community of learning and training for blockchain, cryptocurrency and Web3 education. Rooted in the belief that financial literacy is itself a language, one that's based on a belief system ofmoney, financial markets, and new technology. The American Crypto Academy is designed with this in mind, to help both individuals and professionals understand the language of the future of finance.
      </p>
      </section>

      {/* FOOTER SECTION */}
      {/* <section className="nalikesFooter">
        <center>
          <a href="https://www.nalikes.com/" target="_blank">
            <h3>Powered By&nbsp; <img src={nalikes}/> </h3>
          </a>
        </center>
      </section> */}
    </div>
  );
}
export default App;